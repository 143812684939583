// $blue: #47bcba !default;
$blue: #1571ED !default;
$blue-light: #6ec7ff !default;
$blue-light-300: #baf1fe !default;
// $dark-slate-blue: #003e55 !default;
// $lapis-blue: #0a516c !default;
$primary-100: #dbf2f2;
$white:#FBFBFB;

$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #cf2637 !default;
$orange: #fd9900 !default;
$yellow: #ebb71a !default;
$green: #439b38 !default;
$teal: #20c997 !default;
$cyan: #279aac !default;
$dark-blue: #398bf7 !default;
$light-pink:#FFF5F5 !default;
$light-blue:#EDF4FD !default;
$light-green:#F1FCF0 !default;
$light-purple:#FCF8FF !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eaeaea !default;
$gray-300: #d9d9d9 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #505858 !default;
$gray-900: #343b3c !default;
$black: #000 !default;

$primary:#f80070!default;
// $secondary: $gray-800 !default;
$secondary: #8a8787 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;


$card-shadow : 0px 4px 8px #00000014;



$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $dark-blue,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
);

$font-family-montserrat: 'Roboto', sans-serif;
$font-family-base: $font-family-montserrat !default;
$font-size-base: 0.95rem !default;
 
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/buttons'; 


$body-bg:                   $white !default;
$body-color:                $gray-900 !default;

$table-selection-row : $primary-100;

$bg-primary: $primary !default; 

$link-color:                              theme-color("primary") !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;

$font-weights: (
  "regular": 300,
  "medium": 400,
  "bold": 500,
  "bolder": 700
)