@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

:root { 
    --alpha-8: 0.8;
    --alpha-6: 0.6;
    --alpha-4: 0.4;
    --alpha-2: 0.2;
    --alpha-1: 0.1;
  } 

  @function weight($weight-name)  {
    @return map-get($font-weights, $weight-name);
  } 

html,
body {
    height: 100%; 
}
body {
  margin: 0;
  font-family: $font-family-base!important;
  font-size: $font-size-base;
  font-weight: weight(medium);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h5{
    font-size: $font-size-base;
}
.font-weight-600 {
    font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-primary{
    background-color: $bg-primary!important;
}
 
.btn{  
    font-weight: weight(bold);
    transition: all .2s ease-out;
}

 
a { 
    color: $primary;
    transition: all .2s ease-out;
    &:hover{ 
        transition: all .2s ease-out;
    }
}
.bg-dark{
    background-color: $gray-900!important;
}

.navbar-brand{
    margin-right: 0rem;
} 

.navbar-items{
    justify-content: flex-end;
    .navbar-nav{
        flex-direction: row;
        align-items: center;
        .nav-item{
            width: 50%; 
        }
    }
    .dropdown-menu{
        padding: 0px;
    }
} 

#root{height: 100%;}
header{
    position: fixed;
    height: 56px;
    width: 100%;
    box-shadow: 0px 15px 18px #00000014;
    z-index: 99;
    .navbar{
        padding: 0px 20px;
        @media(max-width:991px){
            padding: 0px 10px;
            
        }
        // background-color: #de8a8a;
    } 
    .navbar-nav{
        .nav-item{
            .nav-link{
                padding: 0rem 1rem; 
                font-weight: weight(bold);
                color:#fff;
            }
        }
        .user-dd{
             padding: 0px;
             min-width: 280px;
             border: none;
             padding-bottom: 0.5rem;
             box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
             .btn{ 
                 display: block;
                 width: 100%;
                 text-align: left; 
                 font-weight: weight(medium);                  
                 &:hover{ 
                     border-radius: 0px;
                     text-decoration: none; 
                     
                 }
                 &:not(:last-of-type){
                    color: $gray-600; 
                    &:hover{
                        color: $gray-100;
                    }
                    i{
                       opacity: 0.5;
                    }
                }  
             }
        }
    } 
 }
.main-wrapper{
  width: 100%;
  height: 100%;
//   overflow: hidden;  

/*****Lest Side bar********************/   
  .left-sidebar{
    // height: calc(100% - 70px);
    position: fixed;
    z-index: 98;
    top: 56px;
    left: 0;
    width: 240px;
    box-shadow: 7px 0px 80px rgba(0, 0, 0, 0.1);
    background-color: $light;
    .sidebar-container{
        height: calc(100vh - 56px);
        overflow-y: auto;
    }
    .navbar-nav{
        width: 100%;
        margin-top: 1rem;
        .nav-item{
            position: relative; 
            .nav-link{ 
                padding: 15px 20px;
                border-radius: 0px;
                text-align: left;
                &:hover{ 
                    color: $white;
                    transition: all .3s ease-out;
                    .has-arrow{
                        &::after{
                            border-color: $white;
                        }
                    }
                }
                .has-arrow{
                    &::after{
                        position: absolute;
                        content: "";
                        width: 7px;
                        height: 7px;
                        border-color: $gray-500;
                        border-style: solid;
                        border-width: 2px 0 0 2px;
                        margin-left: 10px;
                        transform: rotate(135deg) translateY(-50%);
                        transform-origin: top;
                        top: 28px;
                        right: 25px;
                        transition: all .3s ease-out; 
                        opacity: 0.8;
                    }
                }
                .has-arrow[aria-expanded=open]{
                    &::after{
                        transform: rotate(-135deg) translateY(-50%);
                    }
                }
                i{
                    &:first-of-type{
                        position: absolute;
                        top: 18px;
                        left: 24px;
                    }
                }
                span{
                    display: inline-block;
                    padding-left: 28px; 
                }
                i{ 
                    padding-right: 15px;
                }
                   
            }
            
        }  
        .nav-item {
            .active.nav-link:first-of-type{ 
                color: $white; 
                i{
                    color: $white;
                }
                .has-arrow{
                    &::after{
                        border-color: $white;
                    }
                }

            }
        } 
        .navbar-nav{
            margin-top: 2px;
            min-height: 50px;
            padding-left: 16px;
            .nav-item{
                .nav-link{
                    padding-left: 32px;  
                    i{
                        font-size: 6px; ;
                    } 
                    i{
                        &:first-of-type{
                            position: absolute;
                            top: 24px;
                        }
                    }
                    span{
                        display: inline-block;
                        padding-left: 14px; 
                    }
                    &:hover{
                        color: $dark!important;
                    }
                }
            }
        }
    }
    
  } 
  .sidebar-show{
        visibility: visible;
        transition: all .4s ease-out
    }
  .sidebar-hide{
        margin-left: -400px;
        visibility: hidden;
        transition: all .4s ease-out
    }
}


/****Page Content******/
.page-wrapper{
    padding-left: 240px;
    padding-top: 0;
    .page-content{
        padding: 30px;
        height: calc(100vh - 70px);
        overflow: auto;
    }
} 

/****Authu Page****/
 
.no-auth-main-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $white;
  height: 100%;
  .form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
  }
}
.otp-input{
    margin: 3rem 0px;
    .inputStyle {
        width: 3rem !important;
        height: 3rem;
        margin: 0;
        font-size: 2rem;
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.3);
    }
}


/******User Profile**************/
.user-profile{ 
    .user-edit-btn{
        position: absolute;
        top: 20px;
        right: 20px;
        height: 40px;
        width: 40px; 
        border-radius: 50%;
        background-color: rgba($primary, var(--alpha-2)); 
        color: $primary;
        border:none; 
    }
    .card-body{
        position: relative;
        .card-title{
            font-size: 18px;
            font-weight: weight(bold); 
        }
        .user-img{
            height: 120px;
            width: 120px;
            margin-right: 1rem;
            background-size: cover;
            border-radius: 50%;
            border: solid 4px rgba($primary, var(--alpha-2))
        } 
        .img-camera-icon{
            position: absolute;
            bottom: 0px;
            left: 80px;
            height: 40px;
            width: 40px;
            border: solid 2px $white;
            border-radius: 50%;
            background-color: $primary;
            line-height: 40px;
            text-align: center;
            color: $white;
        } 
    } 
}

/*******Mail Box Notification***********************/
.mail-badge {
    position: absolute;
    top: 0px;
    left: 0px;
}
.mailbox{
    min-width: 280px;
    .message-center {
        overflow: auto;
        position: relative;
        .message-item{
            border-bottom: 1px solid $gray-300;
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 15px 10px;
            cursor: pointer;
            .mail-contnet{
                width: 85%;
                vertical-align: middle;
                padding-left: 15px;
                .message-title{
                    margin: 5px 0 0;
                }
                .mail-desc{
                    font-size: 13px;
                    display: block;
                    margin: 1px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $gray-600;
                    white-space: nowrap;
                }
                .time{
                    font-size: 12px;
                    display: block;
                    margin: 1px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $gray-500;
                    white-space: nowrap;
                }
            }
            &:hover{
                background: $gray-200;
            } 
        }
    }
}


/***Global Table Style**********************/
.react-bootstrap-table { 
    .table{
         table-layout: inherit !important;
        thead{
            th{
              
                font-size: 14px;
                font-weight: weight(bold);
                white-space: nowrap;
                // text-align: center;
                @media(max-width:991px){
                width: 200px;
                min-width: 200px;
                }
            }
            th:nth-child(2){
               min-width: 385px;
               width: 385px;
            }
        }
        tbody{
            
            td{
                white-space: nowrap;
                font-size: 14px;
                font-weight: weight(medium);
                vertical-align: middle;
                // text-align: center;
            }
        }
        .selection-row{
            background-color: $primary-100!important;
        } 
        input{
            &:checked{
                background-color: $primary;
                border-color: $primary;
            }
            
        }
    }
    .table-striped{
        tbody{
            tr{
                &:nth-of-type(odd){
                    background-color: $gray-100;
                } 
            }
        }
    }
    
}
.react-bootstrap-table-pagination{
    font-size: 14px;
    font-weight: weight(medium);
    .react-bootstrap-table-pagination-total{
        color: $gray-600; 
        padding-left: 10px;
    }
    .dropdown-toggle {
        padding: 2px 10px; 
        border: none;   
    }
    .dropdown-menu {
        top: -120px;
        border: none;
        border-radius: 0px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        .dropdown-item {
          border-bottom: solid 1px $gray-300;
          &:last-child {
            border-bottom: none;
          }
        }
      } 
       
    .react-bs-table-sizePerPage-dropdown{
        .dropdown-menu{
            .dropdown-item{
                padding: 0;
                a{
                    display: block;
                    padding: 0.25rem 1.5rem;
                }
            }
        }
    }
    .react-bootstrap-table-pagination-list{
        .react-bootstrap-table-page-btns-ul{
            float: right;
        } 
        .pagination {
            .page-item {
              .page-link {
                border-radius: 4px;
                // color: $gray-600;
                margin: 0px 2px;
                padding: 0.25rem 0.5rem;
                // background-color: $white;
                border-color: $white; 
                // &:hover {
                //   background-color: $primary;
                //   border-color: $primary;
                //   color: $white;
                // }
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }
            // .active {
            //   .page-link {
            //     background-color: $primary !important;
            //     color: $white !important;
            //   }
            // }
          }
    }
}

/*********SnapShot Cards************/
.lstick {
    // background: #398bf7;
    width: 2px;
    height: 55px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 15px;

} 
.card{
    .vr {
        margin-left: 5px;
        margin-right: 5px;
        align-self: stretch;
        width: 1px;
        margin-left: 5px;
        margin-right: 5px;
        background-color: $gray-600;
        opacity: 0.5;
        min-height: 70px;
        align-self: auto;
      }
}

/***************Forms*********************/
.react-datepicker__tab-loop{
    .react-datepicker-popper{
        z-index: 9;
    }
}
.form-control{
    font-size: .875rem;
    font-weight: weight(regular);
    line-height: 1.5;
    color: $gray-900;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-300;
    border-radius: 2px;
    box-shadow: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus{
        color: $gray-600; ;
        background-color: $white;
        border-color: $gray-500;
        outline: 0;
        box-shadow: none;
    }
}
.card{
    .card-header {
        padding: 0.45rem 1.25rem;
    }
    .card-title{
        margin-bottom: 0px;
        font-weight: weight(medium);
    }
}
.input-select-sm {
    height: calc(1.2em + 0.75rem + 1px);
}
.main-wrapper{
    padding-top: 56px;
}

.no-auth-main-container .form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
}
.no-auth-main-container {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    // background-color: #e4e3e8;
    height: 100%;
}
.left-sidebar{
    min-height: calc(100vh - 56px);
}
.zindex{
    z-index: 1;
}
.word-break {
    word-break: break-all;
}
.card{
    box-shadow: $card-shadow;
}
.snapshot {
    .card{
        // min-height: 85px;
    }
}
.danger-light{ 
    background-color: rgba($danger, var(--alpha-2))!important;
    border-color: rgba($danger, var(--alpha-6));
    .lstick{ background-color: $danger;}
    h6{ font-weight: weight(medium);}
}
.primary-light{ 
    background-color: rgba($primary, var(--alpha-2))!important;
    border-color: rgba($primary, var(--alpha-6));
    .lstick{ background-color: $primary;}
    h6{ font-weight: weight(medium);}
}
.success-light{ 
    background-color: rgba($success, var(--alpha-2))!important;
    border-color: rgba($success, var(--alpha-6));
    .lstick{ background-color: $success;}
    h6{ font-weight: weight(medium);}
}
.purple-light{ 
    background-color: rgba($purple, var(--alpha-2))!important;
    border-color: rgba($purple, var(--alpha-6));
    .lstick{background-color: $purple;}
    h6{ font-weight: weight(medium);}
}  
.footer-signup{
    position: fixed;
    bottom: 0;
    left: 45px;
}
.login-card{
    height: 100vh;
}
.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    &:before {
      content: '';
      background: linear-gradient(to right, transparent, #818078, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;
      padding: 0 .5em;
      line-height: 1.5em;
      color: #818078;
      background-color: #fcfcfa;
    }
  }
  .login-signup-font{
      font-size: 18px;
  }
 
  @mixin btnSmall($padding-x,$padding-y) {
    padding: $padding-x $padding-y !important;
  }
  .btnSmall{
      @include btnSmall(0.15rem, 0.5rem)
  } 
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: none !important;
} 
// calender css
.demo-app {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }
  
  .demo-app-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
  }
  
  .demo-app-sidebar-section {
    padding: 1em;
  }
  
  .demo-app-main {
    flex-grow: 1;
    padding: 3em;
  }
  iframe {

  display: none !important;

}
 .media-img-status{
    position: absolute;
    background: #000000a6;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 10px;
    color: #fff;
 }
 .item-checkbox{
    position: absolute;
    top: 7px;
    height: 20px;
    width: 20px;
    right: 15px;
    z-index: 99;
 }
 .banner-edit{
    position: absolute;
    width: 20px;
    right: 70px;
    z-index: 9;
    top:12px;
 }

 .media-img-wrapper{
     width: 100%;
     height: 175px;
     overflow: hidden;
     display: flex;
     justify-content:center ;
     align-items: center;
img{
    max-height: 175px;
    min-width: 100%;
}
 }
 .post-type-radio{
    background: #fff;
    padding: 10px 15px;
    border: 1px solid #e8e8e8;
    margin-top: 0;
    margin-bottom: 0;
    height: 42px;
 }

 iframe {
 display: none !important;

  }
  .infinite-scroller{
      overflow: auto;
      height: 300px;
  }
  .media-tab-content{
    height: calc(100vh - 306px);
    overflow-y: auto;
  }
  .tab-wrapper{
      border: 2px solid #80808045;
      padding: 10px;
  }
 
  iframe {
    display: none !important;
   
     }
  .tags-wrapper{
      border: 1px solid #80808082;
    height: 400px;
    padding: 15px;
  }
  .badge-font-wrapper{
      font-size: 15px;
  }
//   .delete-tag-button{
//        height: 100%;
//     width: 40px;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     margin-left: 6px;
//     border-radius: 29px;
//   }
//   .btn{min-width: 96px;}
.tags-dropdown{
   background-color: white !important;
    color: black !important;
}
.comment-wrapper{
    height: 100px;
    overflow: auto;
    padding: 10px;
}
.custom-tag {
    // max-width: 200px;
    width: 150px;
    height: 150px;
}
.carousel-slide {
    width: 150px;
    height: 150px;
    
}
.position-absolute-gallery {
    position: absolute!important;
}
.badge-gallery {
    padding: 5px 15px !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    color: #777 !important;
    text-align: center !important;
    background-color: #f3efe9 !important;
    border-radius: 20px;
    width: 90px;
}
.nav-tabs .nav-link.active{
    border-color: #f80070;
    background-color: #f80070;
    color: #fff;
}
.media-tab{
    width: 50%;
    text-align: center;
    cursor: pointer;
}
.search-input
label{
    width: 100%;
}
.delete-tag{
    font-size: 22px !important;
    color: #f80070;
}
.rati-logo{
    height: 80px;
    width: 80px;
}
// [type="file"] {
// /* Style the color of the message that says 'No file chosen' */
//   color: #878787;
// }
// [type="file"]::-webkit-file-upload-button {
//   background: #f80070;
//   border: 2px solid #ED1C1B;
//   border-radius: 4px;
//   color: #fff;
//   cursor: pointer;
//   font-size: 12px;
//   outline: none;
//   padding: 8px 20px;
//   text-transform: uppercase;
//   transition: all 1s ease;
// }

// [type="file"]::-webkit-file-upload-button:hover {
//   background: #fff;
//   border: 2px solid #535353;
//   color: #000;
// }

// /* GENERAL STYLING OF PAGE — NOT APPLICABLE TO EXAMPLE */
// body {
//   padding: 15px;
// }
// table tbody {
//   display: block;
//   max-height: 420px;
//   overflow-y: scroll;
// }

// table thead, table tbody tr {
//   display: table;
//   width: 100%;
//   table-layout: fixed;
// }

// td{
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
.wc-title {
        letter-spacing: 10px;
        color: #f80070;
        margin-bottom: 12px;
    }
    .rati-title {
        font-size: 20px;
        letter-spacing: 10px;
        color: #f80070;
        margin-bottom: 40px;
        .dark-title {
            font-weight: 600;
        }
    }
.cross{
        position: absolute;
        right: 0;
        width: 380px;
        cursor: pointer;
}
.cross-pdf
{
         position: absolute;
        right: 0;
        width: 288px;
        cursor: pointer;
.wc-title {
        letter-spacing: 10px;
        color: #f80070;
        margin-bottom: 12px;
    }
.rati-title {
    font-size: 20px;
    letter-spacing: 10px;
    color: #f80070;
    margin-bottom: 40px;
    .dark-title {
        font-weight: 600;
    }
}
.col-open-for-free-user{
    margin-top: 35px;
}
}
.banner-wrapper{
    border: 2px solid #80808045;
    padding: 10px;
}
.post-title{
    margin-left: 10px;
    color: #f80070;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 52%;
    text-align: left;
    text-transform: capitalize;
    vertical-align: middle;
}
.carousel-control-next, .carousel-control-prev {
    background-color: gray;
} 
.image-warapper{
    border: 1px solid #80808045;
    padding: 10px;
}
.merget-tag-btn{
    padding-top: 29px;
}
.input-group-addon i {
    margin-left: -47px;
    margin-top: 40px;
    cursor: pointer;
    z-index: 200;
    position: absolute;
    font-size: large;
    color: #6c757d;
  }
 .cross-video{
     position: absolute;
        left: 272px;
        width: 288px;
        cursor: pointer;
}
.choose-file{
       opacity: 0;
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.post-upload-thumbnail{
    margin-left: 60px;
        height: 37px;
            top: 50px;
}
.spinner{
    margin-left: 38px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b1aeb2 #ffffff;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 4px
}
*::-webkit-scrollbar-track {
  background: #ffffff;
}
*::-webkit-scrollbar-thumb {
  background-color: $pink;
  border-radius: 10px;
  border: 3px solid $pink;
}
.tags-btn-alignment{
    padding-top: 30px;
}
.invoice-logo{
    height: 60px;
    margin-top: 5px;
}
.print-button{
    height: 40px;
    margin-top: 2px;
}
.invoice-table{
    table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
}
.invoice{
    border: 1px solid gray;
}
.invoice-gst{
        margin: 9px;
    border: 1px solid #80808073;
}

.page-wrapper{
    width: 100%;
}
